import mainjson from './main';
export default function GetData() {
        return {
        "extraoptions":[
            {
                "name":"Translations",
                "page":"/translations",
            }
        ],
        "apitype":"translationcategories",
        "id":"translation_category_id",
        "options":["edit","insert"],
        "nameSingle":"category",
        "nameMultiple":"categories",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"translation_category_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            }
        }
    }
}